<template>
  <CSidebar fixed :minimize="minimize" :show.sync="show">
    <CSidebarBrand to="/">
      <img
        :src="`${$store.state.common.publicPath}img/logo-white.png`"
        class="c-sidebar-brand-full"
        alt="Logo"
        width="200"
        style="object-fit: cover;"
        height="40"
      />
      <img
        :src="`${$store.state.common.publicPath}img/favicon.png`"
        class="c-sidebar-brand-minimized"
        alt="Logo"
        width="25"
        height="25"
      />
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="nav" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="minimize = !minimize"
    />
  </CSidebar>
</template>

<script>
import nav from "./_nav";

export default {
  name: "TheSidebar",
  data() {
    return {
      minimize: false,
      nav,
      show: "responsive"
    };
  },
  mounted() {
    this.$root.$on("toggle-sidebar", () => {
      const sidebarOpened = this.show === true || this.show === "responsive";
      this.show = sidebarOpened ? false : "responsive";
    });
    this.$root.$on("toggle-sidebar-mobile", () => {
      const sidebarClosed = this.show === "responsive" || this.show === false;
      this.show = sidebarClosed ? true : "responsive";
    });
  }
};
</script>
